import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import CustomerLoveIconImage from "images/simple-icon.svg";

import CustomHeader from "components/custom/Header";

import horacioFoto from "../images/horacio.jpeg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import TwoColumnWithImageAndProfilePictureReview
    from "../components/testimonials/TwoColumnWithImageAndProfilePictureReview";
import TwoColumnWithFeaturesAndTestimonial from "../components/hero/TwoColumnWithFeaturesAndTestimonial";
import TwoColumnWithImage from "../components/testimonials/TwoColumnWithImage";
import {Footer} from "../components/custom/Footer";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <CustomHeader textBlack />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Nuestra Visión"
        buttonRounded={false}
        primaryButtonText="Contactanos"
        primaryButtonUrl="/contacto"
        imageSrc="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
        description="Es ser una empresa de servicios líder en su sector, reconocida como innovadora, flexible y amistosa que, en base a un fuerte compromiso de cada uno de sus miembros, contribuye al mejoramiento de los procesos de sus empresas clientes."
      />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Nuestra Misión"
        buttonRounded={false}
        primaryButtonText="Contactanos"
        primaryButtonUrl="/contacto"
        imageSrc="https://images.unsplash.com/photo-1517048676732-d65bc937f952?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
        textOnLeft={false}
        description={
          <>
            Contribuir al fortalecimiento de la capacidad competitiva de la
            empresa y al mejoramiento de la calidad de vida del personal de la
            misma. <br />
            Para lo cual apuntamos a la PREVENCION con un significado amplio,
            ligado a la idea del trabajo bien hecho, tomando a la prevención
            como acción precedida de reflexión» Esto significa que para
            prevenir, es necesario prever, predecir y preactuar.
            <br /> La misión específica de nuestra empresa en su organización es
            la de generar en cada integrante de la misma una actitud y un
            comportamiento proactivo, teniendo por sustento una responsabilidad
            colectiva frente a la seguridad.
          </>
        }
      />
      <TeamCardGrid subheading={<Subheading>Nuestro Equipo </Subheading>} />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Nuestra Historia"
        buttonRounded={false}
        primaryButtonText="Contactanos"
        primaryButtonUrl="/contacto"
        imageSrc="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
        description={
          <>
            Nuestra empresa es una compañía creada para brindar servicios
            especializados en las áreas de Prevención de riesgos laborales, que
            ha brindado servicios orientados a lograr lugares de trabajo seguros
            y saludables desde hace más de 20 años.<br></br>CSP ASOCIADOS
            proporciona personal capacitado, experimentado y calificado para
            apoyar todos los aspectos de las actividades comerciales en todo el
            país.<br></br>Nos sentimos orgullosos de nuestro trabajo y deseamos
            servirles de la mejor manera posible.<br></br>{" "}
            <b>Cuenta con nosotros.</b>
          </>
        }
      />
      <Features
        subheading={<Subheading></Subheading>}
        heading="Asesoramiento"
        description=""
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Consultoría Profesional",
            description:
              "Nuestros profesionales nos permiten aseverar que los servicios que brindamos tienen el grado de profesionalidad y actualización que requiere su empresa.",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Valores que importan",
            description:
              "Lograr la participación activa, con respecto a la seguridad de todas las personas que trabajan en la empresa.",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Cultura Organizacional",
            description:
              "Lograr un cambio de conducta, generando conductas de autorresponsabilidad y respeto base de toda convivencia humana.",
          },
          {
            imageSrc: CustomizeIconImage,
            title: "Riesgos bajo control",
            description:
              "Que la empresa trabaje con los riegos bajo control es uno de nuestros principales objetivos estratégicos.",
          },
        ]}
        linkText=""
      />
        {/*<TwoColumnWithImage/>*/}

      <Footer />
    </AnimationRevealPage>
  );
};
