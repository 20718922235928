import React  from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import styled from "styled-components";
import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

import userContext from "UserContext.jsx";

import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";

import servicios from "./Servicios.js";

import LogoSrc from "images/logo.png";

function CustomHeader({ textBlack = false }) {
  const StyledHeader = textBlack
    ? styled(Header)`
        ${tw`pt-8 max-w-none`}
        ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
          ${tw`text-gray-700 hover:border-gray-300 hover:text-gray-300`}
          img {
            ${tw`w-auto mr-3`}
          }
        }
        ${NavToggle}.closed {
          ${tw`text-gray-700 hover:text-primary-500`}
        }
      `
    : styled(Header)`
        ${tw`pt-8 max-w-none`}
        ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
          ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
          img {
            ${tw`w-auto mr-3`}
          }
        }
        ${NavToggle}.closed {
          ${tw`text-gray-100 hover:text-primary-500`}
        }
      `;

  const handleDropdownChange = (e) => console.log(e);

  const menu = (
    <Menu>
      {servicios.map((servicio, index) => (
        <MenuItem
          key={index}
          style={{ padding: 0 }}
          onClick={() => console.log("")}
        >
          <NavLink
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              padding: 10,
              margin: 0,
              cursor: "pointer",
            }}
            href={"/servicios/" + servicio.url}
          >
            {servicio.title}
          </NavLink>
        </MenuItem>
      ))}
    </Menu>
  );

  const navLinks = [

      <NavLinks key={1}>
      <NavLink href="/sobre-nosotros">Sobre nosotros</NavLink>
      <Dropdown overlay={menu}>
        <NavLink href="/servicios">Nuestros Servicios</NavLink>
      </Dropdown>
      <NavLink href="/capacitaciones">Capacitaciones</NavLink>
      
    </NavLinks>,
      <NavLinks key={2}>
        <PrimaryLink
          href="/modalidades"
          style={{ color: "rgba(247,250,252,var(--tw-text-opacity))" }}
        >
          Modalidades de servicio
        </PrimaryLink>
      </NavLinks>

    /* <NavLinks key={3}>
      <button onClick={() => auth.signOut()}>signout</button>,
    </NavLinks>, */
  ];

  const customLogoLink = (
    <LogoLink key={3} href="/">
      <img src={LogoSrc} alt="logo" />
    </LogoLink>
  );

  return (
    <div tw="z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto">
      <StyledHeader links={navLinks} logoLink={customLogoLink} />
    </div>
  );
}

export default CustomHeader;
