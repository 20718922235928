import React from "react";
import styled from "styled-components";

function ScrollingBrands() {
  const Container = styled.div`
    
    width: 1500px;
    height: 5vh;
    overflow-x: auto;
    overflow-y: hidden;
    `;

const InnerContainer = styled.div`
  position: relative;
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @keyframes animatedBackground {
  from {
    left: 0px;
  }
  90% {
    left: -562px;
  }
  100%{left: 0px;}
}
animation: animatedBackground 15s linear infinite;
`;
  const Brand = styled.img`
    width: 5vh;
  `;
  const imgSources = [
    "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png",
    "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png",
    "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png",
    "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png",
    "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png",
  ];

  const brandElements = imgSources.map((src) => <Brand src={src} />);

  return <Container><InnerContainer>{brandElements}</InnerContainer></Container>;
}

export default ScrollingBrands;
