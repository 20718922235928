import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import AboutUs from "pages/AboutUs";

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
// import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
// import Features from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "components/pricing/ThreePlans.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "components/cards/TabCardGrid.js";

// import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// import ContactUsForm from "components/forms/SimpleContactUs.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "components/cta/GetStarted.js";
// import GetStarted from "components/cta/GetStartedLight.js";
// import DownloadApp from "components/cta/DownloadApp.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/FiveColumnWithBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
// import Footer from "components/footers/MiniCenteredFooter.js";

/* Ready Made Pages (from demos folder) */
// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
// import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

/* Inner Pages */
// import LoginPage from "pages/Login.js";
// import SignupPage from "pages/Signup.js";
// import PricingPage from "pages/Pricing.js";
// import AboutUsPage from "pages/AboutUs.js";
// import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";
import ThankYouPage from "ThankYouPage.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import BackgroundAsImageWithCenteredContent from "components/hero/BackgroundAsImageWithCenteredContent";
import AgencyLandingPage from "demos/AgencyLandingPage";
import SimplePrimaryBackground from "components/testimonials/SimplePrimaryBackground";
import GridWithFeaturedPost from "components/blogs/GridWithFeaturedPost";
import Login from "pages/Login";
import Signup from "pages/Signup";

import Contacto from "components/custom/Contacto";
import Contenido from "components/custom/Contenido";
import { auth } from "firebase";

import userContext from "UserContext";
import Servicios from "components/custom/CustomServicio";
import MainServicios from "components/custom/MainServicios";
import TwoPlansWithDurationSwitcher from "./components/pricing/TwoPlansWithDurationSwitcher";
import AnimationRevealPage from "./helpers/AnimationRevealPage";
import CustomHeader from "./components/custom/Header";
import PortfolioTwoCardsWithImage from "./components/cards/PortfolioTwoCardsWithImage";
import ThreeColSimpleWithImageAndDashedBorder from "./components/blogs/ThreeColSimpleWithImageAndDashedBorder";
import Novedades from "./components/custom/Novedades";
import {Footer} from "./components/custom/Footer";
import {Capacitaciones} from "./components/custom/Capacitacion";




export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  return (
      <Router>
        <Switch>
          <Route path="/sobre-nosotros">
            <AboutUs />
          </Route>
          <Route path="/servicios/:servicio">
            <Servicios />
          </Route>
          <Route path="/servicios">
            <MainServicios />
          </Route>
          <Route path="/capacitaciones">
            <Capacitaciones/>
          </Route>
          /*<Route path="/novedades">
            <Novedades/>
          </Route>
          <Route path="/modalidades">
            <AnimationRevealPage>
              <CustomHeader textBlack />
              <TwoPlansWithDurationSwitcher
              heading={'Modalidades de servicio'}
              description={'Elige la modalidad que mejor se adapte a tus necesidades'}
              subheading={null}
              plans={[
                {
                  name: "Básica",
                  mainFeature: "Modalidad básica online",
                  features: ["• Determinación de auditoría documental on line para establecer nivel de cumplimiento de la empresa en relación a requisitos legales aplicables", "• Acceso a portal de consultas on line, lugar donde podras realizar consultas puntules sobre temas de seguridad y salud en el trabajo que no impliquen visitas presenciales al lugar de trabajo", "• Acceso a catalogo de cartillas técnicas, formularios, procedimientos genericos"]
                },
                {
                  name: "Presencial",
                  mainFeature: "Modalidad presencial",
                  features: ["• Determinación de auditoría documental on line para establecer nivel de cumplimiento de la empresa en relación a requisitos legales aplicables y plan de  accion asociado", "• Determinación de auditoría operacional presencial para establecer nivel de cumplimiento de la empresa en relación a requisitos legales aplicables y plan de acción asociado", "• Apoyo en proceso de conformación de Comisión Bipartita de Seguridad según lo establecido en Decreto 291-007 (No incluye asistencia a reuniones de forma presencial)", "• Elaboración de Plan de prevención de riegos", "• Capacitación en modalidad e-learning sobre conceptos generales de Seguridad y salud en el trabajo (cantidad maxima 30 personas) "]
                },
                /*{
                  name: "Integral",
                  mainFeature: "Modalidad presencial",
                  features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
                },
                {
                  name: "Premium",
                  mainFeature: "Modalidad presencial",
                  features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
                },*/
                  ]}
              primaryButtonText={"Más información"}
              primaryButtonRoute={'/contacto'}
              />
            </AnimationRevealPage>
            <Footer/>
          </Route>
          <Route path="/contenido">
            <Contenido />
          </Route>
          <Route path="/contacto">
            <Contacto />
          </Route>
          <Route path="/">
            <AgencyLandingPage />
          </Route>
        </Switch>
      </Router>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
