import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import CustomHeader from "./Header";
import ThreeColSimpleWithImageAndDashedBorder from "../blogs/ThreeColSimpleWithImageAndDashedBorder";

import Imagen1 from "../../images/novedades/imagen1.jpeg";
import Imagen2 from "../../images/novedades/imagen2.jpeg";
import Imagen3 from "../../images/novedades/imagen3.jpeg";
import {Footer} from "./Footer";

export default function Novedades(){
    const blogPosts = [

    {
        imageSrc: Imagen1,
        author: "Autor 1",
        category: "Marketing",
        title: "How to Get More Clients",
        description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
        imageSrc: Imagen2,
        author: "Autor 1",
        category: "Marketing",
        title: "How to Get More Clients",
        description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
        imageSrc: Imagen3,
        author: "Autor 1",
        category: "Marketing",
        title: "How to Get More Clients",
        description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
]
    return (<AnimationRevealPage>
              <CustomHeader textBlack />
              <ThreeColSimpleWithImageAndDashedBorder
                subheading="Mantente informado"
                heading="Novedades"
                blogPosts={blogPosts}
              />
            <Footer/>
              </AnimationRevealPage>

    )
}