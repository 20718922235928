import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
// import SimpleIconImage from "../../images/simple-icon.svg";
import CheckImage from "../../images/new/check.svg";
import CheckListImage from "../../images/new/checklist.svg";
import MonitorImage from "../../images/new/monitor.svg";
import Monitor1Image from "../../images/new/monitor1.svg";
import BooksImage from "../../images/new/books.svg";
import SthetoscopeImage from "../../images/new/sthetoscope.svg";
import SnowflakeImage from "../../images/new/snowflake.svg";

const servicios = [
  {
    title: "Plan de Prevención de Riesgos Laborales",
description: (
  <>
    <ul>
      <br></br>○ Análisis de Riesgos Laborales
      <br />
      <div style={{ marginLeft: "2em" }}>
        <b>Identificación y evaluación de riesgos</b> en el lugar de trabajo para prevenir accidentes y enfermedades ocupacionales. Incluye revisión de procesos, condiciones de trabajo y elaboración de un plan de acción.
      </div>
      <br />
      <br></br>○ Formación y Capacitación en Prevención
      <br></br>
      <div style={{ marginLeft: "2em" }}>
        Programas dirigidos a empleados y gerencia sobre prácticas de trabajo seguro y uso de equipo de protección personal. Se fomenta una <b>cultura de seguridad</b>.
      </div>
      <br />
      <br />○ Evaluación de Ergonomía y Puestos de Trabajo
      <br></br>
      <div style={{ marginLeft: "2em" }}>
        Estudio ergonómico para prevenir trastornos musculoesqueléticos, mejorando la comodidad y eficiencia de los empleados mediante ajustes en los entornos de trabajo.
      </div>
      <br></br>
      <br></br>○ Vigilancia de la Salud
      <br></br>
      <div style={{ marginLeft: "2em" }}>
        Programas de seguimiento de la salud de los trabajadores con exámenes médicos periódicos para detectar afectaciones relacionadas con el trabajo.
      </div>
      <br></br>
      <br></br>○ Medidas de Emergencia y Evacuación
      <br></br>
      <div style={{ marginLeft: "2em" }}>
        Desarrollo de planes de emergencia y evacuación, con entrenamientos y simulacros para garantizar una correcta actuación en caso de emergencia.
      </div>
      <br></br>
    </ul>
  </>
),
imageSrc: "https://images.unsplash.com/photo-1634618775687-a8a67b351ea1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
url: "prevencion-riesgos-laborales",
iconSrc: SnowflakeImage,
shortDescription: "Implementamos planes integrales de prevención de riesgos laborales para crear entornos de trabajo seguros y saludables",

  },
  {
    title: "Diagnóstico en materia de seguridad y salud ocupacional",
    description: (
      <>
        <h1>
          <b>Diagnóstico inicial de situación.</b>
        </h1>
        <ul>
          <br />
          Basado en: relevamientos, inspecciones, observación de tareas y
          encuestas al personal. Se realiza así un diagnóstico de la situación
          actual de probabilidad de pérdida de la organización. La tarea de
          diagnóstico se divide en dos subgrupos: trabajo de investigación y
          verificación del cumplimiento de la normativa vigente aplicable.
          <br></br>
          <br></br>
          <b>○ Evaluación de cumplimiento de requisitos legales.</b>
          <br />
          <br></br>
          <b>○ Identificaciones de peligros y evaluación de riesgos</b>
          <br></br>
          &ensp;&ensp; <b>• Mapeo de riesgos de la empresa</b>
          <br />
          <div style={{ marginLeft: "2em" }}>
            Es decir una representación gráfica del diagnóstico elaborado
            previamente. En el mismo se pueden visualizar de forma clara y
            rápida las condiciones y acciones subestándar detectadas,
            clasificando los riegos según su criticidad haciendo foco en
            aquellos que su resultado es no aceptable para la empresa.
          </div>
          &ensp;&ensp; <b>• Plan de prevención integral</b>
          <br />
          <div style={{ marginLeft: "2em" }}>
            Una vez controlados los riesgos inicialmente identificados, se debe
            elaborar un plan para eliminar y/o controlar definitivamente los
            factores causales, ya que estos siempre son los mismos y son los que
            provocan la aparición de nuevos riesgos.
          </div>
          <br></br>
          <br></br>○ Grado de implantación de Sistemas de Gestión de Seguridad y
          Salud Ocupacional.
          <br />
          <br></br>○ Auditorías internas.
          <br></br>
          <br></br>○ Análisis de interrelación con otros sistemas de Gestión.
        </ul>
      </>
    ),

    imageSrc:
      "https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    url: "diagnostico",
    shortDescription:
        "Basado en: relevamientos, inspecciones, observación de tareas y encuestas al personal. Se realiza así un diagnóstico de la situación actual de probabilidad de pérdida de la organización.",
    iconSrc: CheckImage,
  },
  {
    title: "Mediciones higiénicas",
    description: (
      <>
        <ul>
          <br></br>○ Mapeo de ruidos
          <br />
          <div style={{ marginLeft: "2em" }}>
            <b>Sonometrías</b>orientadas a la evaluación del riesgo auditivo en
            el ambiente industrial.
          </div>
          <br />
          <div style={{ marginLeft: "2em" }}>
            personales del riesgo físico ruido.
          </div>
          <div style={{ marginLeft: "2em" }}>
            Nuestros técnicos determinan los niveles de presión sonora ruido en
            base a protocolos estandarizados según las recomendaciones NIOSH,
            empleando equipos de última generación. Se realiza el mapeo de ruido
            estableciendo nivel y ubicación de las fuentes. Este estudio
            constituye la base para programas de control y mejora del ambiente
            de trabajo. Las mediciones se efectúan de acuerdo con las normas
            nacionales e internacionales aplicables.
          </div>
          <br></br>
          <br></br>○ Medición de niveles de iluminación.
          <br></br>Medición de niveles de luz en el ambiente laboral. Se
          determinan los niveles de iluminación en los lugares de trabajo
          comparándolos con los niveles de referencia establecidos por la
          normativa vigente. Este estudio constituye la base para programas de
          control y mejora del ambiente de trabajo. Las mediciones se efectúan
          por diferentes metodologías de acuerdo a la realidad y característica
          de la empresa por puesto de trabajo o utilizando, la metodología de la
          cuadricula, para espacios comunes de acuerdo con las normas nacionales
          e internacionales aplicables para este tipo de estudios.
          <br></br>
          <br />○ Evaluación de estrés térmico.
          <br></br>Evaluación del riesgo de estrés térmico por el método de
          índice WBGT. Este estudio permite determinar el riesgo de estrés
          térmico presente y establecer si son necesarias medidas de control.
          Esta indicado en puestos de trabajo con una carga física importante.
          <br></br>
          <br></br>○ Evaluación de condiciones de ventilación.
          <br></br>Se determinan las condiciones de ventilación mediante
          anemómetro y se establece la tasa de renovación de aire limpio y
          fresco comparándola con los niveles de referencia establecidos por la
          normativa vigente.
          <br></br>
          Este estudio permite conocer la situación y proponer medidas de
          control.
          <br />
          <br />○ Mapeo de riesgos de la empresa
          <br />
          &ensp;&ensp; • Concentraciones peligrosas de gases.
          <br /> &ensp;&ensp; • Espirometrías.
          <br /> &ensp;&ensp; • Imágenes termográficas a los efectos del
          mantenimiento predictivo.
        </ul>
      </>
    ),
    imageSrc:
      "https://images.unsplash.com/photo-1634618775687-a8a67b351ea1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
    url: "mediciones",
    iconSrc: SnowflakeImage,
    shortDescription:
        "Ofrecemos mediciones de ruidos, mediciones de niveles de iluminación, evaluaciónes de estrés térmico, entre muchas otras",
  },
  {
    title: "Identificación y Verificación de Cumplimiento de Requisitos Legales",
    description: (
      <>
        <ul>
          <br></br>○ Análisis de Normativa Aplicable
          <br />
          <div style={{ marginLeft: "2em" }}>
            <b>Revisión exhaustiva de las leyes, normativas y reglamentaciones</b> aplicables al sector de actividad de la empresa, asegurando la identificación completa de requisitos legales pertinentes.
          </div>
          <br />
          <br></br>○ Evaluación de Cumplimiento Legal
          <br></br>
          <div style={{ marginLeft: "2em" }}>
            Evaluaciones periódicas para verificar el cumplimiento de los requisitos legales identificados, incluyendo auditorías internas y la preparación para inspecciones externas.
          </div>
          <br />
          <br />○ Actualización de Requisitos Legales
          <br></br>
          <div style={{ marginLeft: "2em" }}>
            Proceso continuo de monitoreo de cambios en la legislación para garantizar que la empresa se mantenga al día con nuevas obligaciones y ajustes necesarios en sus operaciones.
          </div>
          <br></br>
          <br></br>○ Formación sobre Obligaciones Legales
          <br></br>
          <div style={{ marginLeft: "2em" }}>
            Programas de capacitación para directivos y empleados sobre las obligaciones legales vigentes, promoviendo una cultura de cumplimiento normativo.
          </div>
          <br></br>
          <br></br>○ Documentación y Evidencia de Cumplimiento
          <br></br>
          <div style={{ marginLeft: "2em" }}>
            Implementación de sistemas de gestión documental para registrar y almacenar evidencia del cumplimiento de requisitos legales, facilitando el acceso a la información durante auditorías.
          </div>
          <br></br>
        </ul>
      </>
    ),
    imageSrc: "https://images.unsplash.com/photo-1634618775687-a8a67b351ea1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
    url: "cumplimiento-requisitos-legales",
    iconSrc: SnowflakeImage,
    shortDescription: "Aseguramos el cumplimiento de requisitos legales a través de un enfoque integral que incluye análisis, evaluación, formación y documentación.",
    
  },
  {
    title: "Control Operacional",
    iconSrc: CheckListImage,
    shortDescription:
        "Proponemos las medidas de control más eficientes para cada caso, cumpliendo con la normativa vigente aplicable y logrando que el riesgo este bajo control.",
    description: (
        <>
          <ul>
            <h1>
              Proponemos las medidas de control más eficientes para cada caso,
              cumpliendo con la normativa vigente aplicable y logrando que el
              riesgo este bajo control.
            </h1>
            <br />
            <br></br>○ Inspecciones planeadas y no planeadas
            <br></br>
            <br></br>○ Observaciones de tareas contra estándares
            <br></br>
            <br></br>○ Control y verificación de cumplimiento de requisitos
            legales
            <br></br>
            <b>○ Investigación, comunicación y registro de incidentes</b>
            <br></br>Según datos estadísticos cada 60 accidentes leves, se
            producen aproximadamente 600 incidentes, los cuales bajo condiciones
            ligeramente diferentes pueden generar un accidente. Es por esto que la
            investigación de incidentes proporciona información valiosa para poder
            anticiparnos a la ocurrencia de hechos mas costosos. La investigación
            de estos incidentes genera además una base de datos para un posterior
            tratamiento estadístico.
            <br></br>
            <br></br>○ Confección y seguimiento de Planes de Acción
            <br></br>Elaboración de propuestas de control inmediata. En función de
            la identificación inicial de riesgos y de su clasificación se elaboran
            una serie de propuestas de control, las mismas van desde la
            elaboración de un sistema de mantenimiento preventivo y predictivo,
            hasta un plan de capacitación de personal.
            <br></br>
            <br></br>○ Elaboración de estándares y procedimientos de trabajo con
            medidas de control en materia de seguridad y salud ocupacional
            <br></br>
            <br></br>○ Elaboración de reglamentos internos para empresas
            <br></br>
            <br></br>○ Confección de permisos de trabajos especiales
            <br></br>
            <br></br>○ Confección y control de estándares para contratistas y
            proveedores:
            <br></br>Evaluación de proveedores. Realizando un relevamiento de los
            servicios que su organización contrata, se evalúa la incidencia de los
            mismos en las condiciones de seguridad e higiene, para luego elaborar
            un plan de control de proveedores en materia de seguridad e higiene
            laboral.
            <br></br>
            <br></br>○ Gestión del cambio.
          </ul>
        </>
    ),
    imageSrc:
        "https://images.unsplash.com/photo-1542744173-05336fcc7ad4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1402&q=80",
    url: "control-operacional",
  },
  {
    title: "Planes de emergencia",
    description: (
        <>
          <ul>
            <br></br>○ Control de equipos de lucha contra incendios.
            <br></br>
            <br></br>○ Confección de planes de emergencia y contingencia: El plan
            coordinará las tareas de todos los actores durante la emergencia,
            estableciendo lo que cada uno debe hacer, a modo de ejemplo indicamos
            algunos de los aspectos que tiene en cuenta.
            <br></br>
            <br></br>○ Roles de emergencia.
            <br />
            <br></br>○ Asesoramiento para la realización de simulacros.
            <br></br>
            <br></br>○ Instrucciones de evacuación para el personal no involucrado
            en atender el suceso que produjo la emergencia.
            <br />
            <br></br>○ Aviso y coordinación con los organismos de control
            competentes en la materia servicios de bomberos, emergencia médica
            policía y otros.
            <br></br>
            <br></br>○ Coordinación de la actuación de la brigada de intervención.
            <br></br>
            <br></br>○ Procedimiento de detención de los procesos críticos.
            <br></br>
            <br></br>○ Asegurar las comunicaciones.
            <br />
            <br></br>○ Asegurar los suministros necesarios durante la emergencia.
            <br />
            <br></br>○ Proveer personal y medios para el movimiento de materiales.
            <br />
            <br></br>○ Atender a los medios de prensa.
            <br />
            <br></br>○ Registro y documentación de los hechos para su posterior
            análisis.
          </ul>
        </>
    ),
    shortDescription:
        "Control de equipos de lucha contra incendios Confección de planes de emergencia y contingencia: El plan coordinará las tareas de todos los actores durante...",
    imageSrc:
        "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
    url: "planes-autoproteccion",
  },
  
  {
    title: "Salud Ocupacional",
    iconSrc: SthetoscopeImage,
    shortDescription:
        "Programas de salud adaptados a las necesidades específicas de su empresa elaborados en función de los riesgos higiénicos, químicos y ergonómicos relevados , las características personales y colectivas de sus colaboradores.",
    description: (
      <>
        <ul>
          <br></br>○ Desarrollo de programas de salud ocupacional. Programas de
          salud adaptados a las necesidades específicas de su empresa elaborados
          en función de los riesgos higiénicos, químicos y ergonómicos relevados
          , las características personales y colectivas de sus colaboradores y
          los requisitos establecidos por la normativa legal aplicable Ordenanza
          145 MSP.
          <br />
          Los programas incluyen: VIGILANCIA DE LA SALUD. Protocolo de
          vigilancia médica conforme a lo establecido a la Ordenanza Nº 145/09.
          <br></br>
          <br></br>○ Controles médicos periódicos en función a los riesgos
          asociados. Realización de una historia clínica laboral para todos los
          colaboradores.
          <br></br>
          <br></br>○ Control y verificación de cumplimiento de requisitos
          legales
          <br />
          <br></br>○ Atención de consultas.
          <br></br>
          <br></br>○ Controles de despistaje.
          <br />
          <br></br>○ Seguimiento de la rehabilitación de funcionarios en el
          Banco de Seguros del Estado y DISSE.
          <br></br>
          <br></br>○ Control médico pre-ocupacional para funcionarios que
          ingresan o cambian de actividad.
          <br></br>
          <br></br>○ Controles de exposición a sustancias tóxicas.
          <br></br>
          <br></br>○ Evaluación de los riesgos específicos de cada sustancia
          utilizada.
          <br />
          <br></br>○ Capacitación del personal en el manejo de riesgos, primeros
          auxilios, uso de equipos de protección, manejo de productos peligrosos
          y otros.
        </ul>
      </>
    ),
    imageSrc:
      "https://images.unsplash.com/photo-1638202993928-7267aad84c31?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    url: "salud-ocupacional",
  },
  {
    title: "Asesoramiento e implantación de sistemas de gestión",

    iconSrc: ReliableIconImage,
    shortDescription:
        "Sistema de Gestión de Seguridad y Salud Ocupacional ISO 45001. Sistema de Gestión de Seguridad Vial ISO 39001. Sistema de Gestión de Calidad ISO 9000. Sistema de Gestión Ambiental ISO 14000.",
    description: (
      <>
        <ul>
          <br></br>○ Sistema de Gestión de Seguridad y Salud Ocupacional ISO
          45001.
          <br></br>
          <br></br>○ Sistema de Gestión de Seguridad Vial ISO 39001.
          <br></br>
          <br></br>○ Sistema de Gestión de Calidad ISO 9000.
          <br />
          <br></br>○ Sistema de Gestión Ambiental ISO 14000.
          <br></br>
          <br></br>○ Sistema de Gestión en Seguridad en la Cadena de Suministros
          ISO 28000 (OEC Operador Económicamente Calificado).
          <br />
          <br></br>○ Seguimiento y medición de la implantación del sistema
          <br />
          <div style={{ marginLeft: "2em" }}>
            • Diseño de indicadores de desempeños proactivos y reactivos en
            materia de seguridad y salud ocupacional.
          </div>
          <br />
          <div style={{ marginLeft: "2em" }}>
            • Elaboración de estadísticas de siniestralidad laboral.
          </div>
        </ul>
      </>
    ),
    imageSrc:
      "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
    url: "sistemas-gestion",
  },
];

export default servicios;
