import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose text-justify`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  // /sobre-nosotros
  // /servicios
  // /productos
  // /contacto
  // /ingresar

  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      title: "Nuestra Visión",
      description:
        "Es ser una empresa de servicios líder en su sector, reconocida como innovadora, flexible y amistosa que, en base a un fuerte compromiso de cada uno de sus miembros, contribuye al mejoramiento de los procesos de sus empresas clientes.",
      url: "/sobre-nosotros",
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",

      title: "Nuestra Misión",
      description:
        "Contribuir al fortalecimiento de la capacidad competitiva de la empresa y al mejoramiento de la calidad de vida del personal de la misma. Para lo cual apuntamos a la PREVENCION con un significado amplio, ligado a la idea del trabajo bien hecho, tomando a la prevención como «acción precedida de reflexión» Esto significa que para prevenir, es necesario prever, predecir y preactuar.La misión específica de nuestra empresa en su organización es la de generar en cada integrante de la misma una actitud y un comportamiento proactivo, teniendo por sustento una responsabilidad colectiva frente a la seguridad.",
      url: "/sobre-nosotros",
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1574073763042-9dbe6ae03853?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",

      title: "Nuestra estrategia",
      description:
        "Integramos calidad y seguridad al servicio de su organización, pilares para el aseguramiento del trabajo bien hecho, fomentando el trabajo en equipo.",
      url: "/sobre-nosotros",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1536895058696-a69b1c7ba34f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",

      title: "Nuestros objetivos",
      description:
        "Disminuir los accidentes que ocurren en su empresa. Que la empresa trabaje con los riegos bajo control. Lograr la participación activa, con respecto a la seguridad de todas las personas que trabajan en la empresa. Generar un cambio de conducta, generando conductas de autorresponsabilidad y respeto base de toda convivencia humana. Llevar registros que permitan monitorear permanentemente las acciones implementadas.",
      url: "/sobre-nosotros",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>Más Detalles</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
