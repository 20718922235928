import React from "react";

import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";

import CustomHeader from "./Header";
import AnimationRevealPage from "helpers/AnimationRevealPage";

function Contacto() {
return (
    <AnimationRevealPage>
  <CustomHeader textBlack/>
<TwoColContactUsWithIllustrationFullForm subheading="Contactenos" heading={<>Siéntase libre de <span tw="text-primary-500">contactarnos</span><wbr/> por cualquier consulta.</>} description={null}/>
</AnimationRevealPage>)
}

export default Contacto;
