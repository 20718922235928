import FiveColumnWithInputForm from "components/footers/FiveColumnWithInputForm";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import ThreePlansWithHalfPrimaryBackground from "components/pricing/ThreePlansWithHalfPrimaryBackground";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import React from "react";
import CustomHeader from "./Header";

function Contenido() {
  return (
    <AnimationRevealPage greenBack>
      <CustomHeader />
      <ThreePlansWithHalfPrimaryBackground />
      <FiveColumnWithInputForm />
    </AnimationRevealPage>
  );
}

export default Contenido;
