import GridWithFeaturedPost from "components/blogs/GridWithFeaturedPost";
import FiveColumnWithInputForm from "components/footers/FiveColumnWithInputForm";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import React from "react";
import CustomHeader from "./Header";
import servicios from "./Servicios";
import {Footer} from "./Footer";

function MainServicios() {
  const posts = servicios.map((servicio, index) => {
    return {
      postImageSrc: servicio.imageSrc,
      title: servicio.title,
      description: servicio.shortDescription,
      url: "/servicios/" + servicio.url,
      featured: false
    };
  });

  return (
    <AnimationRevealPage>
      <CustomHeader textBlack />
      <GridWithFeaturedPost
        heading="Nuestros Servicios"
        subheading=""
        posts={posts}
      />
      <Footer />
    </AnimationRevealPage>
  );
}

export default MainServicios;
