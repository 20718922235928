import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import CustomHeader from "./Header";
import PortfolioTwoCardsWithImage from "../cards/PortfolioTwoCardsWithImage";
import {Footer} from "./Footer";
import tuCampusFoto from "../../images/logotucampus.png";
import ifplogo from "../../images/logoifp.png";

export function Capacitaciones() {
    return (
        <AnimationRevealPage>
            <CustomHeader textBlack />
            <PortfolioTwoCardsWithImage
                subheading=""
                headingHtmlComponent="Alianzas estratégicas."
                description=""
                cards={[
                    {
                        imageSrc: ifplogo,
                        company: "IFP Uruguay",
                        type: "Formación en prevención",
                        title: "Profesionales en capacitación en prevención de riesgos laborales",
                    },
                    {
                        imageSrc: tuCampusFoto,
                        company: "Tu Campus",
                        type: "Plataforma de capacitación",
                        title: "Expertos en cursos asincrónicos",
                    }
                    ]}

            />
            <PortfolioTwoCardsWithImage
                textOnLeft
                subheading="Capacitaciones"
                headingHtmlComponent="Planes de capacitación a medida"
                description="Nuestro equipo de trabajo está conformado por profesionales con amplia experiencia en el sector de la salud, con el fin de brindar un servicio de calidad y eficaz a nuestros clientes."
                cards={[
                    {
                        company: "",
                        title: "",
                    },
                    {
                        company: "",
                        title: "",
                    }
                ]}

            />
            <Footer/>
        </AnimationRevealPage>
    );
}