import TwoColSingleFeatureWithStats from "components/features/TwoColSingleFeatureWithStats";
import TwoColSingleFeatureWithStats2 from "components/features/TwoColSingleFeatureWithStats2";
import FiveColumnWithInputForm from "components/footers/FiveColumnWithInputForm";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import React from "react";
import { useParams } from "react-router-dom";
import CustomHeader from "./Header";
import servicios from "./Servicios";
import {Footer} from "./Footer";

function Servicios() {
  const { servicio } = useParams();

  const s = servicios.filter((s) => s.url === servicio)[0];

  return (
    <AnimationRevealPage>
      <CustomHeader textBlack />
        {servicio && <TwoColSingleFeatureWithStats
        heading={s.title}
        description={s.description}
        imageSrc={s.imageSrc}
        statistics={[]}
      />}
      <Footer />
    </AnimationRevealPage>
  );
}

export default Servicios;
